import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_RELATED_SYMPTOM](state, action) {
        const {csid, symptomId} = action.payload;
        return {
            ...state,
            [csid]: {
                id: csid,
                symptomId,
            },
        };
    },
    [actions.data.REMOVE_RELATED_SYMPTOM](state, action) {
        const csid = action.payload;
        const newState = {...state};
        delete newState[csid];
        return newState;
    },
});
