import {combineReducers} from 'redux';

import demographicsReducer from 'accounts/reducers/demographicsReducer.js';
import feedbackQuestionsReducer from 'accounts/reducers/feedbackQuestionsReducer.js';
import patientRecreationalDrugsReducer from 'accounts/reducers/patientRecreationalDrugsReducer.js';
import patientReducer from 'accounts/reducers/patientReducer.js';
import clinicalConditionsReducer from 'clinical/reducers/clinicalConditionsReducer.js';
import clinicalMedicationsReducer from 'clinical/reducers/clinicalMedicationsReducer.js';
import clinicalSymptomsReducer from 'clinical/reducers/clinicalSymptomsReducer.js';
import constitutionalSymptomsReducer from 'clinical/reducers/constitutionalSymptomsReducer.js';
import medicationDosagesReducer from 'clinical/reducers/medicationDosagesReducer.js';
import recreationalDrugsReducer from 'clinical/reducers/recreationalDrugsReducer.js';
import consultationReducer from 'consultations/reducers/consultationReducer.js';
import consultationSymptomsReducer from 'consultations/reducers/consultationSymptomsReducer.js';
import familyConditionsReducer from 'consultations/reducers/familyConditionsReducer.js';
import medicationCoursesReducer from 'consultations/reducers/medicationCoursesReducer.js';
import pastConditionsReducer from 'consultations/reducers/pastConditionsReducer.js';
import relatedSymptomsReducer from 'consultations/reducers/relatedSymptomsReducer.js';
import reviewOfSystemsReducer from 'consultations/reducers/reviewOfSystemsReducer.js';

export const accountsReducer = combineReducers({
    demographics: demographicsReducer,
    patient: patientReducer,
    patientRecreationalDrugs: patientRecreationalDrugsReducer,
    feedbackQuestions: feedbackQuestionsReducer,
});

export const clinicalReducer = combineReducers({
    conditions: clinicalConditionsReducer,
    symptoms: clinicalSymptomsReducer,
    constitutionalSymptoms: constitutionalSymptomsReducer,
    medications: clinicalMedicationsReducer,
    recreationalDrugs: recreationalDrugsReducer,
    medicationDosages: medicationDosagesReducer,
});

export const consultationsReducer = combineReducers({
    consultation: consultationReducer,
    consultationSymptoms: consultationSymptomsReducer,
    familyConditions: familyConditionsReducer,
    medicationCourses: medicationCoursesReducer,
    pastConditions: pastConditionsReducer,
    relatedSymptoms: relatedSymptomsReducer,
    reviewOfSystems: reviewOfSystemsReducer,
});

export default combineReducers({
    accounts: accountsReducer,
    clinical: clinicalReducer,
    consultations: consultationsReducer,
});
