import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {
    previousSteps: [],
    progress: {
        addends: [0],
        finalStepAddend: false,
        increments: [],
        value: 0,
    },
    reloading: false,
    stepSequence: 0,
    steps: [],
};

export default createReducer(initialState, {
    [actions.form.ADD_STEPS](
        state,
        {payload: {steps, parentId = null, newIndex}},
    ) {
        let sequence = state.stepSequence;
        const stepsToAdd = steps.map((step) => ({
            id: sequence++,
            component: {
                type: step.type,
                completed: false,
                props: step.props || {},
            },
            undo: false,
            undoable: step.undoable === undefined ? true : step.undoable,
            loading: false,
            reloaded: false,
            parent: parentId,
            children: [],
        }));

        let stepIndex;
        const newSteps = state.steps.map((step, index) => {
            if (step.id !== parentId) {
                return step;
            }
            stepIndex = newIndex || index;
            return {
                ...step,
                children: [
                    ...step.children,
                    ...stepsToAdd.map((step) => step.id),
                ],
            };
        });

        if (parentId === null) {
            newSteps.push(...stepsToAdd);
        } else {
            newSteps.splice(stepIndex + 1, 0, ...stepsToAdd);
        }
        return {
            ...state,
            stepSequence: sequence,
            steps: newSteps,
        };
    },
    [actions.form.REMOVE_STEP](state, {payload: {stepId}}) {
        return {
            ...state,
            steps: state.steps.filter((step) => step.id !== stepId),
        };
    },
    [actions.form.REMOVE_CHILDREN_STEPS](state, {payload: {stepId}}) {
        const currentStep = state.steps.find((step) => step.id === stepId);
        const stepsToRemove = currentStep.children;
        currentStep.children = [];
        return {
            ...state,
            steps: state.steps.filter(
                (step) => !stepsToRemove.includes(step.id),
            ),
        };
    },
    [actions.form.SET_STEP_ATTR](state, {payload: {stepId, field, value}}) {
        const step = state.steps.find((step) => step.id === stepId);
        const fields = field.split('.');
        let ref = step;
        fields.forEach((name, index) => {
            if (index === fields.length - 1) {
                ref[name] = value;
            } else {
                ref = ref[name];
            }
        });
        return {
            ...state,
            steps: [...state.steps],
        };
    },
    [actions.form.SET_FORM_RELOADING](state, {payload: {reloading}}) {
        return {
            ...state,
            reloading,
        };
    },
    [actions.form.UPDATE_PROGRESS](state, {payload}) {
        /*
            increment - An amount by which we increase the progress value.
                        Each time an undoable step(s) is added, a new
                        increment is calculated as:

                            remaining progress / remaining undoable steps

                        Increments are stored in an array to keep a history
                        of all the increments.

            addends - The amounts that make up the total progress value.
                      The addends are stored in an array to make progress value
                      calculation deterministic, and to avoid JavaScript's
                      inaccurate floating point arithmetic.

                      When a step is completed, a new addend is pushed. The
                      amount for the addend is based on the most recent
                      increment.
        */

        if (
            !state.steps.find(
                (step) => step.component.type === 'SubmitConsultationStep',
            )
        ) {
            // steps inside addInitialDefaultSteps such as WalkInPatientFlowStep
            // defer the addition of SubmitConsultationStep until it is submitted
            // which causes the progress bar to jump to 100% at the start
            return state;
        }

        const {update, parentId, undoable} = payload;
        const newState = {...state};
        switch (update) {
            case actions.form.POP_ADDEND: {
                if (undoable) {
                    newState.progress.addends.pop();
                } else if (state.progress.finalStepAddend) {
                    newState.progress.addends.pop();
                    newState.progress.finalStepAddend = false;
                }
                break;
            }
            case actions.form.PUSH_ADDEND: {
                const {increments} = state.progress;
                newState.progress.addends.push(
                    increments[increments.length - 1].increment,
                );
                break;
            }
            case actions.form.POP_INCREMENT: {
                newState.progress.increments = state.progress.increments.filter(
                    (incr) => incr.parentId !== parentId,
                );
                break;
            }
            case actions.form.PUSH_INCREMENT: {
                const remainingProgress = 100 - state.progress.value;
                const undoStepIndex = state.steps
                    .map((step) => step.component.completed && step.undoable)
                    .lastIndexOf(true);
                const remainingUndoableSteps = state.steps
                    .slice(undoStepIndex + 1)
                    .filter((step) => step.undoable);

                if (!remainingUndoableSteps.length) {
                    break;
                }

                // padding is required in case checkpoint steps
                // add additional step(s) towards the end of the
                // consultation, in order to make those final
                // increments look more natural
                const padding = 1;
                const increment =
                    remainingProgress /
                    (remainingUndoableSteps.length + padding);
                const previousIncrement =
                    state.progress.increments[
                        state.progress.increments.length - 1
                    ];
                if (increment !== previousIncrement?.increment) {
                    // prevents duplicate increments caused by repeatedly
                    // submitting and undoing steps that get added by
                    // addInitialDefaultSteps
                    newState.progress.increments.push({
                        parentId,
                        increment,
                    });
                }
                break;
            }
            case actions.form.RELOAD_INCREMENTS: {
                // On reload, step IDs can change. So we need to
                // map old parentIds to new ones after a reload
                const {previousSteps, steps} = state;
                const {increments} = state.progress;

                const updatedParentIds = {};
                increments.forEach((incr) => {
                    // ignore increments that don't have a parentId
                    // e.g. increments associated with steps added at
                    //      the beginning by addInitialDefaultSteps
                    if (
                        incr.parentId !== undefined &&
                        incr.parentId !== null
                    ) {
                        if (updatedParentIds[incr.parentId] === undefined) {
                            const previousStepIndex = previousSteps.findIndex(
                                (step) => step.id === incr.parentId,
                            );
                            const currentStep = steps[previousStepIndex];
                            updatedParentIds[incr.parentId] = currentStep.id;
                        }
                    }
                });

                newState.progress.increments = increments.map((incr) => ({
                    ...incr,
                    parentId: updatedParentIds[incr.parentId] || null,
                }));
                break;
            }
            case actions.form.FINAL_STEP: {
                // ensures that the progress value is at 100% on the
                // final step. We first check that the final step is reached
                // i.e. all checkpoint steps have been added and the active
                // step index is the last index
                const activeStepIndex = state.steps.findIndex(
                    (step) => !step.component.completed && !step.loading,
                );
                const finalStep = activeStepIndex === state.steps.length - 1;
                const checkPointStepsAdded = state.steps
                    .filter((step) =>
                        step.component.type.endsWith('CheckpointStep'),
                    )
                    .every((step) => step.component.completed);

                if (finalStep && checkPointStepsAdded) {
                    newState.progress.addends.push(100 - state.progress.value);
                    newState.progress.finalStepAddend = true;
                }
                break;
            }
            default:
                return state;
        }
        return {
            ...newState,
            progress: {
                ...newState.progress,
                value: newState.progress.addends.reduce(
                    (sum, addend) => sum + addend,
                ),
            },
        };
    },
});
