import {combineReducers} from 'redux';

import deepMerge from 'utils/deep_merge.js';

import accountsReducer from 'accounts/reducers/accountsReducer.js';
import actions from 'better_consult/actions/types.js';
import appReducer from 'better_consult/reducers/appReducer.js';
import dataReducer from 'better_consult/reducers/dataReducer.js';
import formReducer from 'consultations/reducers/formReducer.js';
import dashboardReducer from 'dashboard/reducers/dashboardReducer.js';
import questionsReducer from 'questions/reducers/questionsReducer.js';

const betterConsultReducer = combineReducers({
    accounts: accountsReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    data: dataReducer,
    form: formReducer,
    questions: questionsReducer,
});

export default function rootReducer(store, action) {
    if (action.type === 'LOGOUT_USER_SUCCESS') {
        /**
         *  Reset every sub store to default,
         *  but let userReducer and appReducer control how to reset their store
         */
        store = {
            accounts: {
                user: store.accounts.user,
            },
            app: store.app,
        };
        // eslint-disable-next-line max-len
    } else if (
        action.type === actions.consultations.RESTORE_CONSULTATION_SUCCESS
    ) {
        store = deepMerge(store, {
            data: action.payload.data,
            form: {
                reloading: true,
                previousSteps: action.payload.steps,
                progress: action.payload.progress,
            },
            questions: action.payload.questions,
        });
    }
    return betterConsultReducer(store, action);
}
