import loadStore from 'store.js';

import {setLanguage} from 'accounts/actions/accountsActions.js';
import {setClientType} from 'better_consult/actions/appActions.js';

export default async function() {
    const preloadedStore = {};
    const language = window.localStorage.getItem('language') || null;
    const userAgent = navigator.userAgent.toLowerCase();
    const clientType = userAgent.includes(' electron/') ? 'app' : 'web';
    const store = await loadStore(preloadedStore);
    await store.dispatch(setClientType(clientType));
    await store.dispatch(setLanguage(language));
    return store;
}
