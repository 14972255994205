import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_PAST_CONDITIONS](state, action) {
        const response = action.payload;
        const newState = {...state};
        for (const item of response) {
            const existingItem = newState[item.id] || {};
            newState[item.id] = {
                ...existingItem,
                ...item,
            };
        }
        return newState;
    },
    [actions.data.REMOVE_PAST_CONDITIONS](state, action) {
        const {ids} = action.payload;
        const newState = {...state};
        for (const id of ids) {
            delete newState[id];
        }
        return newState;
    },
    [actions.data.SET_PAST_CONDITION_ATTR](state, action) {
        const {id, field, value} = action.payload;
        if (id in state) {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [field]: value,
                },
            };
        }
        return state;
    },
    [actions.data.SUBMIT_CONDITION_QUESTION_SUCCESS](state, action) {
        const {id, qid, value} = action.payload;
        const newState = {...state};
        if (id in newState) {
            const questionResults = newState[id].questionResults || {};
            questionResults[qid] = value;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    questionResults,
                },
            };
        }
        return state;
    },
    [actions.data.UNDO_SUBMIT_CONDITION_QUESTION_SUCCESS](state, action) {
        const {id, qid} = action.payload;
        const newState = {...state};
        if (id in newState) {
            delete newState[id].questionResults[qid];
            return newState;
        }
        return state;
    },
});
