import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const defaultApp = {
    supportedLanguages: [],
    supportedMessages: {},
    modal: {
        type: null,
        props: {},
    },
    mustComplyWithHipaa: null,
    notification: {
        isTimedNotice: false,
        primaryButton: {
            action: null,
            text: null,
        },
        secondaryButton: {
            action: null,
            text: null,
            urlPath: null,
        },
        showOverlay: false,
        timedNoticeDelay: null,
        title: null,
        type: null,
    },
    client: null,
    sid: null,
};

export default createReducer(defaultApp, {
    [actions.app.SHOW_MODAL](store, action) {
        return {
            ...store,
            modal: {
                ...store.modal,
                type: action.payload.type,
                props: action.payload.props,
            },
        };
    },
    [actions.app.HIDE_MODAL](store) {
        return {
            ...store,
            modal: {
                ...store.modal,
                type: null,
                props: {},
            },
        };
    },
    [actions.app.SHOW_NOTIFICATION](store, action) {
        const {
            isTimedNotice,
            primaryButton = {action: null, text: null},
            secondaryButton = {action: null, text: null, urlPath: null},
            showOverlay,
            timedNoticeDelay,
            title,
            type,
        } = action.payload.notificationDetails;
        return {
            ...store,
            notification: {
                ...store.notification,
                isTimedNotice,
                primaryButton,
                secondaryButton,
                showOverlay,
                timedNoticeDelay,
                title,
                type,
            },
        };
    },
    [actions.app.HIDE_NOTIFICATION](store) {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line redux-nested-objects/require-spread-redux-nested-objects
        return {
            ...store,
            notification: {
                ...store.notification,
                isTimedNotice: false,
                primaryButton: {
                    action: null,
                    text: null,
                },
                secondaryButton: {
                    action: null,
                    text: null,
                    urlPath: null,
                },
                showOverlay: false,
                timedNoticeDelay: null,
                title: null,
                type: null,
            },
        };
    },
    [actions.accounts.SET_LANGUAGE_SUCCESS](store, action) {
        const {languageCode, messages} = action.payload;
        return {
            ...store,
            supportedMessages: {
                ...store.supportedMessages,
                [languageCode]: messages,
            },
        };
    },
    [actions.app.SET_CLIENT_TYPE](store, action) {
        return {
            ...store,
            client: action.payload,
        };
    },
    [actions.app.SET_SID](store, action) {
        return {
            ...store,
            sid: action.payload,
        };
    },
    [actions.accounts.LOGOUT_USER_SUCCESS](store) {
        return {
            ...store,
            modal: defaultApp.modal,
        };
    },
    [actions.app.SET_MUST_COMPLY_WITH_HIPAA](store, action) {
        return {
            ...store,
            mustComplyWithHipaa: action.payload,
        };
    },
});
