import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';
import deduplicate from 'utils/deduplicate.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_CONSULTATION_SYMPTOM](state, action) {
        const response = action.payload;
        const existingItem = state[response.id] || {};
        return {
            ...state,
            [response.id]: {
                ...existingItem,
                ...response,
            },
        };
    },
    [actions.data.REMOVE_CONSULTATION_SYMPTOM](state, action) {
        const {id} = action.payload;
        const newState = {...state};
        delete newState[id];
        return newState;
    },
    [actions.data.SET_CONSULTATION_SYMPTOM_ATTR](state, action) {
        const {id, field, value, merge} = action.payload;
        if (id in state) {
            const existing = state[id][field] || [];
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [field]: merge
                        ? deduplicate(existing.concat(value))
                        : value,
                },
            };
        }
        return state;
    },
    // eslint-disable-next-line max-len
    [actions.data.REMOVE_RELATED_SYMPTOM_FROM_PRESENTING_SYMPTOM](
        state,
        action,
    ) {
        const {id, relatedSymptomId} = action.payload;
        if (id in state && state[id].relatedSymptoms) {
            state[id].relatedSymptoms = state[id].relatedSymptoms.filter(
                (symptomId) => symptomId !== relatedSymptomId,
            );
        }
        return state;
    },
    [actions.data.SUBMIT_SYMPTOM_QUESTION_SUCCESS](state, action) {
        const {id, qid, value} = action.payload;
        const newState = {...state};
        if (id in newState) {
            const questionResults = newState[id].questionResults || {};
            questionResults[qid] = value;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    questionResults,
                },
            };
        }
        return state;
    },
    [actions.data.UNDO_SUBMIT_SYMPTOM_QUESTION_SUCCESS](state, action) {
        const {id, qid} = action.payload;
        const newState = {...state};
        if (id in newState) {
            delete newState[id].questionResults[qid];
            return newState;
        }
        return state;
    },
});
