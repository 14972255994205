import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_REVIEW_OF_SYSTEMS](state, action) {
        const response = action.payload;
        const newState = {...state};
        newState[response.id] = {
            ...response,
        };
        return newState;
    },
    [actions.data.REMOVE_REVIEW_OF_SYSTEMS](state, action) {
        const bodySystemId = action.payload;
        const newState = {...state};
        Object.values(newState).forEach((state) => {
            if (state.bodySystem === bodySystemId) {
                delete newState[state.id];
            }
        });
        return newState;
    },
});
