import autobind from 'common/decorators/autobind.js';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import http from 'http.js';
import {CONSULTATIONS_FEEDBACK_API} from 'apiEndpoints.js';

import {handleError} from 'better_consult/actions/appActions.js';
import {getStepAndProblemData} from 'consultations/actions/consultationActions.js';
import ContentHelper from 'better_consult/components/ContentHelper.js';
import {FAQ_URL} from 'consultations/helpers.js';
import FormNotice from 'core/components/FormNotice.js';

const messages = defineMessages({
    dismissButtonText: {
        id: 'consultations.FeedbackModal.dismissButtonText',
        defaultMessage: 'dismissButtonText',
    },
    errorFeedbackHeader: {
        id: 'consultations.FeedbackModal.errorFeedbackHeader',
        defaultMessage: 'errorFeedbackHeader',
    },
    errorSuccessMessage: {
        id: 'consultations.FeedbackModal.errorSuccessMessage',
        defaultMessage: 'errorSuccessMessage',
    },
    faq: {
        id: 'consultations.FeedbackModal.faq',
        defaultMessage: 'faq',
    },
    feedback: {
        id: 'consultations.FeedbackModal.feedback',
        defaultMessage: 'feedback',
    },
    feedbackHeader: {
        id: 'consultations.FeedbackModal.feedbackHeader',
        defaultMessage: 'feedbackHeader',
    },
    helperWarning: {
        id: 'consultations.FeedbackModal.helperWarning',
        defaultMessage: 'helperWarning',
    },
    helperContent: {
        id: 'consultations.FeedbackModal.helperContent',
        defaultMessage: 'helperContent',
    },
    helperLabel: {
        id: 'consultations.FeedbackModal.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperTitle: {
        id: 'consultations.FeedbackModal.helperTitle',
        defaultMessage: 'helperTitle',
    },
    submitButtonText: {
        id: 'consultations.FeedbackModal.submitButtonText',
        defaultMessage: 'submitButtonText',
    },
});

export class FeedbackModal extends React.Component {
    static propTypes = {
        closeFunction: PropTypes.func.isRequired,
        setShowClosingConfirm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.helper = {
            title: <p>{props.intl.formatMessage(messages.helperTitle)}</p>,
            label: props.intl.formatMessage(messages.helperLabel),
            content: (
                <span>
                    <p>{props.intl.formatMessage(messages.helperWarning)}</p>
                    <strong>
                        {props.intl.formatMessage(messages.helperContent)}
                    </strong>
                </span>
            ),
        };
        this.state = {
            feedback: '',
            loading: false,
            success: false,
        };
    }

    @autobind
    updateFeedback(event) {
        const value = event.currentTarget.value;
        const shouldShowClosingConfirm = value.length > 0;
        this.props.setShowClosingConfirm(shouldShowClosingConfirm);
        this.setState({feedback: value});
    }

    @autobind
    async handleSubmit(event) {
        event.preventDefault();
        const message = this.state.feedback;
        if (this.props.error) {
            await this.props.handleError({message});
            this.setState({success: true});
        } else {
            if (this.state.loading) {
                return;
            }
            const data = this.props.getStepAndProblemData();
            data.message = message;
            this.setState({loading: true});
            await http.post({
                url: CONSULTATIONS_FEEDBACK_API,
                data,
            });
            this.setState({loading: false, success: true});
            this.props.setShowClosingConfirm(false);
        }
    }

    render() {
        const intl = this.props.intl;
        const faqUrl = FAQ_URL['AU'];
        return (
            <form className="dialog-body" onSubmit={this.handleSubmit}>
                <h1 data-test-id="feedback-modal-heading">
                    {this.props.error
                        ? intl.formatMessage(messages.errorFeedbackHeader)
                        : intl.formatMessage(messages.feedbackHeader)}
                </h1>
                {!this.props.error && (
                    <ContentHelper
                        helperContent={this.helper}
                        openByDefault={true}
                    />
                )}
                {this.state.success && (
                    <FormNotice
                        errorType="success"
                        message={
                            this.props.error ? (
                                intl.formatMessage(
                                    messages.errorSuccessMessage,
                                )
                            ) : (
                                <FormattedMessage
                                    defaultMessage="consultations.FeedbackModal.successMessage"
                                    id="consultations.FeedbackModal.successMessage"
                                    values={{
                                        url: (
                                            <a
                                                href={faqUrl}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                <strong>
                                                    {intl.formatMessage(
                                                        messages.faq,
                                                    )}
                                                </strong>
                                            </a>
                                        ),
                                    }}
                                />
                            )
                        }
                    />
                )}
                <textarea
                    aria-placeholder={intl.formatMessage(messages.feedback)}
                    autoFocus={true}
                    data-test-id="feedback-modal-text-field"
                    name="feedback"
                    onChange={this.updateFeedback}
                    readOnly={this.state.success}
                ></textarea>
                <div className="cta-group">
                    <button
                        className="cta"
                        data-test-id="feedback-modal-submit-button"
                        disabled={
                            !this.state.feedback.length || this.state.success
                        }
                        type="submit"
                    >
                        {intl.formatMessage(messages.submitButtonText)}
                    </button>
                    {!this.props.error && (
                        <button
                            className="cta cta-secondary"
                            data-test-id="feedback-modal-dismiss-button"
                            onClick={this.props.closeFunction}
                            type="button"
                        >
                            {intl.formatMessage(messages.dismissButtonText)}
                        </button>
                    )}
                </div>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        handleError: ({message}) => dispatch(handleError({message})),
        getStepAndProblemData: () => dispatch(getStepAndProblemData()),
    };
}

export default connect(null, mapDispatchToProps)(injectIntl(FeedbackModal));
