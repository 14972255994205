import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {Integrations as ApmIntegrations} from '@sentry/tracing';

import NoMatch from '404.js';
import {loadUser} from 'accounts/actions/accountsActions.js';
import History from 'better_consult/components/History.js';
import Modal from 'better_consult/components/Modal.js';
import Notification from 'better_consult/components/Notification.js';
import autobind from 'common/decorators/autobind.js';
import getComponent from 'componentsList.js';
import setupSentry from 'common/setupSentry.js';
import {captureException} from 'sentry.js';
import TitledRoute from 'core/components/TitledRoute.js';
import events from 'events.js';

// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;
const DEV_ENV = env === 'development';

export class App extends React.Component {
    constructor(props) {
        super(props);
        const release =
            (document.body.dataset && document.body.dataset.release) || '';
        Sentry.init({
            dsn:
                'https://4afdf9276db440af979f0025627d6503@o20735.ingest.sentry.io/194135',
            attachStacktrace: true,
            enabled: !DEV_ENV,
            release,
            integrations: [new ApmIntegrations.BrowserTracing()],
            tracesSampleRate: 0.025,
            environment: env,
            // Allow all errors for the moment
            ignoreErrors: [],
        });
        setupSentry(Sentry, {workflow: 'frontend'});
    }

    async componentDidMount() {
        this.props.loadUser();
        events.listen(document, 'mousedown', this.handleMouseDown);
        events.listen(document, 'keydown', this.handleKeyDown);
        events.listen(document, 'touchstart', this.handleTouch);
    }

    componentWillUnmount() {
        events.unlisten(document, 'mousedown', this.handleMouseDown);
        events.unlisten(document, 'keydown', this.handleKeyDown);
        events.unlisten(document, 'touchstart', this.handleTouch);
    }

    componentDidCatch(error, info) {
        captureException({error, info});
    }

    handleMouseDown() {
        if (document.body.classList.contains('key-controlled')) {
            document.body.classList.remove('key-controlled');
        }
    }

    handleKeyDown() {
        if (!document.body.classList.contains('key-controlled')) {
            document.body.classList.add('key-controlled');
        }
    }

    @autobind
    handleTouch() {
        // blank function for :active state to work on iOS
    }

    render() {
        if (!this.props.userInitialised) {
            return null;
        }
        return (
            <BrowserRouter>
                <>
                    <History />
                    <Modal />
                    <Notification />
                    <Switch>
                        {/* TODO: Move TitledRoute to common,
                        move titles to dedicated file for i18n */}
                        {this.props.routes.map((route) => (
                            <TitledRoute
                                component={getComponent(route.component)}
                                exact={route.exact}
                                key={route.title}
                                name={route.name}
                                path={route.react}
                                routes={route.routes}
                                title={route.title}
                            />
                        ))}
                        <Route component={NoMatch} />
                    </Switch>
                </>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(store) {
    return {
        userInitialised: store.accounts.user.initialised,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadUser: () => dispatch(loadUser()),
    };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(App));
