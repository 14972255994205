import {combineReducers} from 'redux';

import healthFundsReducer from 'accounts/reducers/healthFundsReducer.js';
import userReducer from 'accounts/reducers/userReducer.js';
import resetPasswordReducer from 'accounts/reducers/resetPasswordReducer.js';

export default combineReducers({
    healthFunds: healthFundsReducer,
    resetPassword: resetPasswordReducer,
    user: userReducer,
});
