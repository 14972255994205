import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const defaultUser = {
    initialised: false,
    error: false,
    success: false,
    loggedIn: false,
    id: null,
    authToken: null,
    consultations: [],
    email: null,
    firstName: null,
    lastName: null,
    language: 'en-au',
    patients: {
        items: [],
        success: false,
    },
    patientConfirmed: false,
    patientSelected: false,
    patientSelectedError: false,
    title: null,
    isStaff: null,
    practices: [],
    relatedPractitioners: [],
    selectedPracticeId: null,
    termsOfUse: {
        bannerTerms: null,
        sidebarTerms: null,
        fullscreenTerms: null,
    },
};

export default createReducer(defaultUser, {
    [actions.accounts.CREATE_PATIENT_SUCCESS](store, action) {
        const patient = action.payload;
        store.patients.items.push(patient);
        return {
            ...store,
            patients: {
                ...store.patients,
                items: store.patients.items,
            },
        };
    },
    [actions.accounts.SET_PATIENT_SUCCESS](store) {
        return {
            ...store,
            patientSelected: true,
            patientSelectedError: false,
        };
    },
    [actions.accounts.SET_PATIENT_FAILED](store) {
        return {
            ...store,
            patientSelected: false,
            patientSelectedError: true,
        };
    },
    [actions.accounts.UNDO_UPDATE_PATIENT_SUCCESS](store) {
        return {
            ...store,
            patientConfirmed: false,
        };
    },
    [actions.accounts.GET_USER_FAILED](store) {
        return {
            ...store,
            initialised: true,
            success: false,
            error: true,
        };
    },
    [actions.accounts.GET_USER_SUCCESS](store, action) {
        return {
            ...store,
            initialised: true,
            success: true,
            error: false,
            loggedIn: true,
            id: action.payload.id,
            authToken: action.payload.token,
            email: action.payload.email,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            title: action.payload.title,
            isStaff: action.payload.isStaff,
            practices: action.payload.practices,
            relatedPractitioners: action.payload.relatedPractitioners,
            language: action.payload.language,
            patients: {
                ...store.patients,
                items: action.payload.patients,
                success: true,
            },
            isWalkInUser: action.payload.isWalkInUser,
        };
    },
    [actions.accounts.SELECT_PRACTICE_SUCCESS](store, action) {
        return {
            ...store,
            selectedPracticeId: action.payload,
        };
    },
    [actions.accounts.UPDATE_USER_SUCCESS](store, action) {
        return {
            ...store,
            email: action.payload.email,
            firstName: action.payload.firstName,
            language: action.payload.language,
            lastName: action.payload.lastName,
            title: action.payload.title,
        };
    },
    [actions.accounts.REMOVE_PATIENT_SUCCESS](store, action) {
        const {patientId} = action.payload;
        const patientList = store.patients.items.filter(
            (item) => item.id !== patientId,
        );
        return {
            ...store,
            patients: {
                ...store.patients,
                items: patientList,
            },
        };
    },
    [actions.accounts.GET_CONSULTATIONS_SUCCESS](store, action) {
        return {
            ...store,
            success: true,
            error: false,
            consultations: action.payload,
        };
    },
    [actions.accounts.GET_CONSULTATIONS_FAILED](store) {
        return {
            ...store,
            success: false,
            error: true,
        };
    },
    [actions.accounts.SET_LANGUAGE_SUCCESS](store, action) {
        const {languageCode} = action.payload;
        return {
            ...store,
            language: languageCode,
        };
    },
    [actions.accounts.LOGOUT_USER_SUCCESS](store) {
        return {
            ...defaultUser,
            language: store.language,
            initialised: store.initialised,
        };
    },
    [actions.accounts.SET_TERMS_OF_USE](store, action) {
        return {
            ...store,
            termsOfUse: action.payload,
        };
    },
});
