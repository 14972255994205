import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_MEDICATION_DOSAGE](state, action) {
        const response = action.payload;
        return {
            ...state,
            [response.id]: {
                ...response,
            },
        };
    },
});
