import {deepClone} from 'utils/deep_merge.js';

export default function(initialState, handlers) {
    return function(state = deepClone(initialState), action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}
