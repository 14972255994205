import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_CONSTITUTIONAL_SYMPTOMS](state, action) {
        const response = action.payload || [];
        const newState = {...state};
        for (const item of response) {
            const existingItem = newState[item.id] || {};
            newState[item.id] = {
                ...existingItem,
                ...item,
            };
        }
        return newState;
    },
});
