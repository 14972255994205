import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {ACCOUNTS_USER_STORE_API} from 'apiEndpoints.js';
import rootReducer from 'better_consult/reducers/rootReducer.js';
import http from 'http.js';
import deepMerge from 'utils/deep_merge.js';

// eslint-disable-next-line no-undef
const env = process.env.NODE_ENV;
let DEBUG;
const DEV_ENV = env === 'development';
try {
    // DEV_ENV may not be defined
    // eslint-disable-next-line no-undef
    DEBUG = DEV_ENV && env !== 'test' && env !== 'production';
} catch (e) {
    DEBUG = false;
}

async function getStore() {
    try {
        const data = await http.get({url: ACCOUNTS_USER_STORE_API});
        return data.store;
    } catch (err) {
        return undefined;
    }
}

function enableBatching(reducer) {
    return function(store, action) {
        switch (action.type) {
            case 'BATCH_ACTIONS':
                return action.actions.reduce(reducer, store);
            default:
                return reducer(store, action);
        }
    };
}

async function preloadStore(injectedStore) {
    let preloadedStore;
    if (injectedStore) {
        preloadedStore = injectedStore;
    } else {
        preloadedStore = await getStore();
        if (preloadedStore) {
            preloadedStore = JSON.parse(preloadedStore);
        }
    }
    return preloadedStore;
}

export default async function loadStore(injectedStore) {
    let middleware;
    if (DEBUG) {
        middleware = composeWithDevTools(applyMiddleware(thunk));
    } else {
        middleware = applyMiddleware(thunk);
    }
    const preloadedStore = (await preloadStore(injectedStore)) || {};
    // merge the default store with the preloaded store
    const defaultStore = rootReducer(undefined, {});
    const baseStore = deepMerge(defaultStore, preloadedStore);
    const store = createStore(
        enableBatching(rootReducer),
        baseStore,
        middleware,
    );
    return store;
}
