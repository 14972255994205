import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_FAMILY_CONDITIONS](state, action) {
        const response = action.payload;
        const newState = {...state};
        for (const item of response) {
            const existingItem = newState[item.id] || {};
            newState[item.id] = {
                ...existingItem,
                ...item,
            };
        }
        return newState;
    },
    [actions.data.REMOVE_FAMILY_CONDITIONS](state, action) {
        const {ids} = action.payload;
        const newState = {...state};
        for (const id of ids) {
            delete newState[id];
        }
        return newState;
    },
    [actions.data.SET_FAMILY_CONDITION_ATTR](state, action) {
        const {fcid, field, value} = action.payload;
        if (fcid in state) {
            return {
                ...state,
                [fcid]: {
                    ...state[fcid],
                    [field]: value,
                },
            };
        }
        return state;
    },
});
