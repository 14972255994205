import types from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const defaultQuestions = {
    global: {
        items: [],
        success: false,
        stepsAdded: false,
    },
};

export default createReducer(defaultQuestions, {
    [types.questions.GET_GLOBAL_QUESTIONS_SUCCESS](store, action) {
        return {
            ...store,
            global: {
                ...store.global,
                success: true,
                items: action.questions,
            },
        };
    },
    [types.questions.SUBMIT_GLOBAL_ANSWERS_SUCCESS](store, action) {
        const items = store.global.items;
        for (const question of items) {
            if (question.id === action.payload.qid) {
                question.selectedAnswers = action.payload.answers;
            }
        }

        return {
            ...store,
            items,
        };
    },
    [types.questions.UNDO_SUBMIT_GLOBAL_ANSWERS_SUCCESS](store, action) {
        const items = store.global.items;
        for (const question of items) {
            if (question.id === action.payload.qid) {
                question.selectedAnswers = [];
            }
        }

        return {
            ...store,
            items,
        };
    },
});
