import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_CLINICAL_CONDITION](state, action) {
        const response = action.payload;
        return {
            ...state,
            [response.id]: {
                ...response,
            },
        };
    },
    [actions.data.REMOVE_CLINICAL_CONDITION](state, action) {
        const {id} = action.payload;
        const newState = {...state};
        delete newState[id];
        return newState;
    },
});
