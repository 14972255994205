import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_MEDICATION_COURSE](state, action) {
        const data = action.payload;
        const existingItem = state[data.id] || {};
        return {
            ...state,
            [data.id]: {
                ...existingItem,
                ...data,
            },
        };
    },
    [actions.data.REMOVE_MEDICATION_COURSE](state, action) {
        const {id} = action.payload;
        const newState = {...state};
        delete newState[id];
        return newState;
    },
    [actions.data.SET_MEDICATION_COURSE_ATTR](state, action) {
        const {id, field, value} = action.payload;
        if (id in state) {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [field]: value,
                },
            };
        }
        return state;
    },
    [actions.data.SUBMIT_MEDICATION_QUESTION_SUCCESS](state, action) {
        const {id, qid, value} = action.payload;
        const newState = {...state};
        if (id in newState) {
            const questionResults = newState[id].questionResults || {};
            questionResults[qid] = value;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    questionResults,
                },
            };
        }
        return state;
    },
    [actions.data.UNDO_SUBMIT_MEDICATION_QUESTION_SUCCESS](state, action) {
        const {id, qid} = action.payload;
        const newState = {...state};
        if (id in newState) {
            delete newState[id].questionResults[qid];
            return newState;
        }
        return state;
    },
});
