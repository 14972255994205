import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const defaultHealthFund = {
    success: false,
    items: [],
};

export default createReducer(defaultHealthFund, {
    [actions.accounts.GET_HEALTH_FUNDS_SUCCESS](store, action) {
        return {
            ...store,
            items: action.payload,
            success: true,
        };
    },
    [actions.accounts.GET_HEALTH_FUNDS_FAILED](store) {
        return {
            ...store,
            success: false,
        };
    },
});
