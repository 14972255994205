import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';
import deepMerge from 'utils/deep_merge.js';

export const defaultDashboard = {
    practices: [],
    relatedPractitioners: [],
    consultations: [],
    patients: [],
    showPractitioners: false,
    showPractices: false,
    showConsultations: false,
    showSearch: false,
    loading: false,
    dashboardErrorCode: '',
    isDemo: false,
};

export default createReducer(defaultDashboard, {
    [actions.dashboard.CLEAR_DATA]() {
        return defaultDashboard;
    },

    [actions.dashboard.SET_DASHBOARD_ERROR](store, action) {
        return {
            ...store,
            dashboardErrorCode: action.payload,
        };
    },

    [actions.dashboard.SET_DATA](state, action) {
        return deepMerge(state, action.payload);
    },

    [actions.dashboard.SET_SELECTED_ATTR](state, action) {
        const {field, value} = action.payload;
        const newElement = state[field].map((el) => {
            if (el.id === parseInt(value)) {
                el.selected = true;
            } else {
                delete el.selected;
            }
            return el;
        });
        return {
            ...state,
            [field]: newElement,
        };
    },
});
