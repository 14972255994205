import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {IntlProvider as ReactIntlProvider, addLocaleData} from 'react-intl';
import {connect} from 'react-redux';

@connect(mapStateToProps)
export default class IntlProvider extends React.Component {
    static propTypes = {
        language: PropTypes.string,
        messages: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        moment.locale(this.props.language);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            moment.locale(this.props.language);
        }
    }

    render() {
        return (
            <ReactIntlProvider
                locale={this.props.language}
                messages={this.props.messages}
            >
                {this.props.children}
            </ReactIntlProvider>
        );
    }
}

function mapStateToProps(store) {
    const language = store.accounts.user.language;
    return {
        language,
        messages: store.app.supportedMessages[language],
    };
}
