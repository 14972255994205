import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_PATIENT](state, action) {
        const response = action.payload;
        if (state.id === response.id) {
            return {
                ...state,
                ...response,
            };
        } else {
            return {...response};
        }
    },
    [actions.data.REMOVE_PATIENT]() {
        return {};
    },
    [actions.data.SET_PATIENT_ATTR](state, action) {
        const {field, value} = action.payload;
        return {
            ...state,
            [field]: value,
        };
    },
});
