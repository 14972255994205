import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';
import deduplicate from 'utils/deduplicate.js';

const initialState = {
    consultationSymptoms: [],
    consultationError: null,
    medicationCourses: [],
    pastConditions: [],
    practice: {},
    reviewOfSystems: [],
};

export default createReducer(initialState, {
    [actions.data.SET_CONSULTATION_ATTR](state, action) {
        const {field, value, merge} = action.payload;
        return {
            ...state,
            [field]: merge ? deduplicate(state[field].concat(value)) : value,
        };
    },
    [actions.data.CONSULTATION_CHECK_TOKEN_SUCCESS](state, action) {
        return {
            ...state,
            ...action.payload,
            consultationError: null,
            tokenChecked: true,
            token: action.token,
        };
    },
    [actions.data.CONSULTATION_CHECK_TOKEN_FAILED](state, action) {
        return {
            ...state,
            consultationError: action.payload,
            tokenChecked: true,
        };
    },
});
