import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_FEEDBACK_QUESTIONS](state, action) {
        const response = action.payload || [];
        const newState = {...state};
        for (const item of response) {
            const existingItem = newState[item.id] || {};
            newState[item.id] = {
                ...existingItem,
                ...item,
            };
        }
        return newState;
    },
    [actions.data.CLEAR_FEEDBACK_QUESTIONS]() {
        return {};
    },
    [actions.data.SUBMIT_FEEDBACK_SUCCESS](state, action) {
        const newState = {...state};
        const item = newState[action.payload.qid];
        item.selectedAnswers = action.payload.answers;
        return newState;
    },
    [actions.data.UNDO_SUBMIT_FEEDBACK_SUCCESS](state, action) {
        const newState = {...state};
        const item = newState[action.payload.qid];
        item.selectedAnswers = [];
        return newState;
    },
});
