import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const defaultResetPassword = {
    emailSuccess: false,
    email: null,
    password: {
        errors: [],
        success: false,
    },
};

export default createReducer(defaultResetPassword, {
    [actions.accounts.SEND_RESET_PASSWORD_EMAIL_FAILED](store) {
        return {
            ...store,
            emailSuccess: false,
        };
    },
    [actions.accounts.SEND_RESET_PASSWORD_EMAIL_SUCCESS](store) {
        return {
            ...store,
            emailSuccess: true,
        };
    },
});
