import autobind from 'common/decorators/autobind.js';
import React from 'react';
import ReactDOM from 'react-dom';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import events from 'events.js';

import {hideModal} from 'better_consult/actions/appActions.js';
import FeedbackModal from 'better_consult/components/FeedbackModal.js';
import FlatPageModal from 'better_consult/components/FlatPageModal.js';
import {getFocusableEls, trapTabKey} from 'utils/focus_helpers.js';

const messages = defineMessages({
    confirmFeedbackModalClose: {
        id: 'better_consult.Modal.confirmFeedbackModalClose',
        defaultMessage: 'confirmFeedbackModalClose',
    },
});

export class Modal extends React.Component {
    constructor() {
        super();

        this.modalMap = {
            'FEEDBACK_MODAL': {
                component: FeedbackModal,
                name: 'FeedbackModal',
            },
            'PRIVACY_MODAL': {
                component: FlatPageModal,
                urlPath: 'privacy-policy/',
                name: 'TermsAndConditionsModal',
            },
            'TERMS_MODAL': {
                component: FlatPageModal,
                urlPath: 'terms-of-use/patient/',
                name: 'TermsAndConditionsModal',
            },
        };
        this.focusableEls = null;
        this.modalEl = null;
        this.originalActiveEl = null;
        this.showClosingConfirm = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.modalType) {
            document.body.classList.add('dialog-open');
            this.setAttribute('modal', 'aria-hidden', false);
            this.setAttribute('app', 'aria-hidden', true);
            events.listen(window, 'keydown', this.handleKeyDown);
        } else if (prevProps.modalType) {
            document.body.classList.remove('dialog-open');
            this.setAttribute('modal', 'aria-hidden', true);
            this.setAttribute('app', 'aria-hidden', false);
            this.originalActiveEl.focus();
            events.unlisten(window, 'keydown', this.handleKeyDown);
        }
    }

    setAttribute(el, attr, val) {
        document.getElementById(el).setAttribute(attr, val);
    }

    @autobind
    handleHideModal() {
        if (this.showClosingConfirm) {
            const modalName = this.modalMap[this.props.modalType].name;
            /* eslint-disable no-alert */
            const confirm = window.confirm(
                this.props.intl.formatMessage(
                    messages[`confirm${modalName}Close`],
                ),
            );
            if (confirm) {
                this.props.hideModal();
                this.showClosingConfirm = false;
            }
        } else {
            this.props.hideModal();
        }
    }

    @autobind
    setShowClosingConfirm(shouldShowClosingConfirm) {
        this.showClosingConfirm = shouldShowClosingConfirm;
    }

    @autobind
    handleKeyDown(event) {
        switch (event.key) {
            case 'Tab':
                if (
                    !this.focusableEls ||
                    !this.focusableEls.includes(document.activeElement) ||
                    this.focusableEls.some((el) => !this.modalEl.contains(el))
                ) {
                    this.focusableEls = getFocusableEls('modal');
                }
                trapTabKey(this.focusableEls, event);
                break;
            case 'Escape':
                this.handleHideModal();
                break;
            case 'Esc': // Edge specific value
                this.handleHideModal();
                break;
            default:
                break;
        }
    }

    renderModal() {
        const modal = this.modalMap[this.props.modalType];
        const ModalComponent = modal.component;
        return (
            <ModalComponent
                closeFunction={this.handleHideModal}
                setShowClosingConfirm={this.setShowClosingConfirm}
                urlPath={modal.urlPath}
                {...this.props.modalProps}
            />
        );
    }

    render() {
        if (this.props.modalType) {
            this.originalActiveEl = document.activeElement;
            this.modalEl = document.getElementById('modal');
            return ReactDOM.createPortal(this.renderModal(), this.modalEl);
        } else {
            return null;
        }
    }
}

function mapStateToProps(store) {
    return {
        modalType: store.app.modal.type,
        modalProps: store.app.modal.props,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideModal: () => dispatch(hideModal()),
    };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Modal));
