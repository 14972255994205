import actions from 'better_consult/actions/types.js';
import createReducer from 'utils/createReducer.js';

const initialState = {};

export default createReducer(initialState, {
    [actions.data.ADD_PATIENT_RECREATIONAL_DRUGS](state, action) {
        const response = action.payload;
        const newState = {};
        for (const item of response) {
            newState[item.id] = {
                ...item,
            };
        }
        return newState;
    },
    [actions.data.SET_PATIENT_RECREATIONAL_DRUG_ATTR](state, action) {
        const {id, field, value} = action.payload;
        if (id in state) {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [field]: value,
                },
            };
        }
        return state;
    },
});
